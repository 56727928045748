import "typeface-roboto";
import { CookiesProvider } from "react-cookie";
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import MainApp from "./components/MainApp";
import { TOKEN } from "./constants";
import config from "./config";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { PaymentConclusionPage } from "./pages/PaymentConclusionPage";
import { muiTheme } from "./theme";
import Reboot from "@material-ui/core/CssBaseline";
import find from "array.prototype.find";
import includes from "array-includes";
import promise from "es6-promise";
import "babel-polyfill";
import Button from "@material-ui/core/Button";
import EventListener from "react-event-listener";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { ApolloClient } from "apollo-client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { ApolloProvider } from "react-apollo";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { ExternalPaymentPage } from "./pages/ExternalPaymentPage";
import PhosphorIcon from "./components/phosphor-icons/phosphor-icon";
import { InMemoryCache } from "@apollo/client";

promise.polyfill();
includes.shim();
find.shim();

class LoginOrApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  resize() {
    setTimeout(function() {
      document.body.style.height = window.innerHeight + "px";
    }, 50);
  }

  reloadPage() {
    window.location.reload(true);
  }

  componentDidMount() {
    this.resize();
  }

  render() {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <EventListener
          target="window"
          onResize={this.resize}
          onStorage={e => {
            if (e.key === TOKEN) {
              this.reloadPage();
            }
          }}
        />
        <Reboot />
        <div
          style={{
            height: "100vh",
          }}
        >
          {localStorage.getItem(TOKEN) ? (
            <Switch>
              <Route
                path="/"
                render={() => (
                  <MainApp
                    apolloClient={this.props.apolloClient}
                    onLogout={() => {
                      localStorage.removeItem(TOKEN);
                      this.props.history.push(this.props.location.pathname);
                    }}
                  />
                )}
              />
            </Switch>
          ) : (
            <LoginPage
              onLogin={token => {
                localStorage.setItem(TOKEN, token);
                this.props.history.push(this.props.location.pathname);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };

    const httpLink = new HttpLink({ uri: config.graphqlHttpUrl });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: config.graphqlWsUrl,
        retryAttempts: 10,
        retryWait: async (retryCount) => {
          console.log(`Retrying connection attempt ${retryCount}`);
          return new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        },
        // connectionParams: async () => {
        //   const token = await getAccessTokenSilently();
        //   return { authorization: token ? `Bearer ${token}` : "" };
        // },
      })
    );

    const logoutLink = onError(arg => {
      let errors = [];
      if (arg.graphQLErrors) {
        errors = arg.graphQLErrors;
      }
      if (arg.networkError) {
        errors.push(arg.networkError);
      }
      errors.forEach(error => {
        if (error.message === "authorization failed") {
          localStorage.removeItem(TOKEN);
          window.location.reload();
        } else {
          console.log("graphql error: ", error);
          this.setState({ error });
        }
      });
    });

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
      },
      logoutLink.concat(wsLink),
      logoutLink.concat(httpLink)
    );

    this.client = new ApolloClient({
      link: link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
      },
    });
  }

  reloadPage() {
    window.location.reload(true);
  }

  render() {
    if (this.state.error) {
      return (
        <MuiThemeProvider theme={muiTheme}>
          <article className="modal-background">
            <section className="modal">
              <div>
                <h4>Error</h4>
              </div>
              <div className="error-message">
                <PhosphorIcon name="warning" className="icon" />
                <p>{this.state.error.message}</p>
              </div>
              <div className="modal-actions">
                <Button onClick={this.reloadPage} color="primary" autoFocus>
                  Reload page
                </Button>
              </div>
            </section>
          </article>
        </MuiThemeProvider>
      );
    }
    return (
      <MuiThemeProvider theme={muiTheme}>
        <ApolloProvider client={this.client}>
          <Switch>
            {/* CHECK - THIS MIGHT NOT BE USED ANYMORE */}
            <Route
              path="/pay/result/:state"
              render={props => (
                <PaymentConclusionPage conclusion={props.match.params.state} />
              )}
            />
            {/* CHECK - THIS MIGHT NOT BE USED ANYMORE */}
            <Route
              path="/pay/:clientId/:externalPaymentId"
              render={props => {
                return (
                  <ExternalPaymentPage
                    apolloClient={this.client}
                    clientId={props.match.params.clientId}
                    externalPaymentId={props.match.params.externalPaymentId}
                  />
                );
              }}
            />
            <Route
              path="/"
              render={() => <LoginOrAppWithRouter apolloClient={this.client} />}
            />
          </Switch>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  }
}

class i18nApp extends React.Component {
  render() {
    return (
      <CookiesProvider>
        <I18nextProvider i18n={i18n}>
          <App history={this.props.history} />
        </I18nextProvider>
      </CookiesProvider>
    );
  }
}

const LoginOrAppWithRouter = withRouter(LoginOrApp);
export default withRouter(i18nApp);
